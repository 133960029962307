import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {User} from '../../models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showNav = false;
  isAuthenticated: boolean;
  user: User;

  constructor(private auth: AuthService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.auth.currentUser.subscribe((user) => {
      this.isAuthenticated = user instanceof User;
      this.user = user;
    });

  }

  logout(): void {
    this.auth.logout();
    this.router.navigate(['login']);
  }

}
