<div class="stepper" *ngIf="route || trips">
  <div class="container-sm">
    <div class="stepper-route" *ngIf="route">
      <dl>
        <dt>
          <a routerLink="/steps/route">Recorrido Elegido</a>
        </dt>
        <dd>
          {{ route.name }}
        </dd>
      </dl>
    </div>

    <div class="stepper-times" *ngIf="trips">
      <dl>
        <dt>
          <a routerLink="/steps/dates">Viajes</a>
        </dt>
        <dd>
          <ul>
            <li *ngFor="let trip of trips">
          <span class="date">
            {{ trip.date | date:'dd/MM/yyyy' }}
          </span>
              <span class="time" *ngIf="timesSelected">
            {{ trip.date | date:'HH:mm' }}
          </span>
            </li>
          </ul>
        </dd>
      </dl>
    </div>
  </div>
</div>

