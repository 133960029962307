
<h1>
  Elegí las fechas en las que querés viajar.
</h1>

<p class="periodic-checkbox-wrapper">
  <label for="periodic-checkbox">
    Comprar Varios Pasajes
    <input type="checkbox" id="periodic-checkbox" [(ngModel)]="multipleDates" (change)="changePeriodic()">
  </label>
</p>

<div class="datepicker" *ngIf="showDatepicker">

  <div class="datepicker-header container">

    <div class="row">
      <button class="col-2 btn btn-default"
              (click)="previousMonth()"
              [disabled]="isCurrentMonth(currentMonth)">
        <fa-icon [icon]="faChevronLeft"></fa-icon>
      </button>
      <div class="col-8 datepicker-month">
        {{ getMonthFullName(currentMonth) }} {{ currentMonth.year }}
      </div>
      <button class="col-2 btn btn-default"
              (click)="nextMonth()">
        <span class="fa fa-chevron-right"></span>
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </button>
    </div>

    <div class="dp-footer row">
      <div class="ngb-dp-week ngb-dp-weekdays bg-light">
        <label class="ngb-dp-weekday small">DO</label>
        <label class="ngb-dp-weekday small">LU</label>
        <label class="ngb-dp-weekday small">MA</label>
        <label class="ngb-dp-weekday small">MI</label>
        <label class="ngb-dp-weekday small">JU</label>
        <label class="ngb-dp-weekday small">VI</label>
        <label class="ngb-dp-weekday small">SA</label>
      </div>
    </div>

  </div>

  <ngb-datepicker #dp
                  [minDate]="minDate"
                  [markDisabled]="isDisabled"
                  [dayTemplate]="dayTemplate"
                  [showWeekdays]="false"
                  [firstDayOfWeek]="7"
                  (select)="onDateSelection($event)"
                  (navigate)="currentMonth = $event.next"
                  [navigation]="'none'"
  ></ngb-datepicker>

  <button id="btn-checkout" class="btn btn-block btn-success btn-lg"
          *ngIf="validDate"
          routerLink="/steps/times"
  >Reservar
  </button>

</div>

<ng-template #dayTemplate let-date>
  <span class="custom-day"
        [class.selected]="isSelected(date)"
        [class.gray]="isDisabled(date)"
        [class.past]="isPast(date)"
        [class.selectedButNA]="isSelected(date) && isDisabled(date)"
  >
    {{ date.day }}
  </span>
</ng-template>
