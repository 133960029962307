import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {CommonModule, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AuthService } from './services/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PurchaseService } from './services/purchase.service';
import { ApiInterceptor } from './api.interceptor';
import { RouteComponent } from './components/steps/route/route.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatesComponent } from './components/steps/dates/dates.component';
import { TimesComponent } from './components/steps/times/times.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckoutComponent } from './components/steps/checkout/checkout.component';
import { StepsComponent } from './components/steps/steps.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { HeaderComponent } from './components/header/header.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from './components/auth/register/register.component';
import {ForgotPasswordComponent} from './components/auth/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/auth/reset-password/reset-password.component';
import {ToastrModule} from 'ngx-toastr';
import { DirectionFilterPipe } from './direction-filter.pipe';
import { ModalNotificationComponent } from './_shared/modal-notification/modal-notification.component';

import localeEsAr from '@angular/common/locales/es-AR';
localeEsAr[14][2] = '¤#,##0.00';
registerLocaleData(localeEsAr);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    RouteComponent,
    DatesComponent,
    TimesComponent,
    CheckoutComponent,
    StepsComponent,
    StepperComponent,
    HeaderComponent,
    TicketsComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DirectionFilterPipe,
    ModalNotificationComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    AuthService,
    PurchaseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-AR' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
