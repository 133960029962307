import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { PurchaseService } from '../../../services/purchase.service';
import { User } from '../../../models/user.model';
import {ToastrService} from 'ngx-toastr';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  user: User = new User();
  errorMessage: any = null;

  constructor(
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) {
  }

  onSubmit(form): void {

    this.auth
      .login(this.user)
      .subscribe((user: User) => {
        this.toastr.success(`¡Bienvenido ${user.name}!`);
        this.router.navigateByUrl('/steps/route');
      });

  }

}
