import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  password: string;
  password_confirmation: string;

  constructor(private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private authService: AuthService) { }

  ngOnInit(): void {


  }

  reset(form): void {

    if (form.invalid) {
      return;
    }

    const data = {
      email: this.route.snapshot.paramMap.get('email'),
      token: this.route.snapshot.paramMap.get('token'),
      password: this.password,
      password_confirmation: this.password_confirmation,
    };

    this.authService
      .resetPassword(data)
      .subscribe(() => {
        this.toastr.success('Cambiaste tu contraseña con éxito.', '¡Buenísimo!');
        this.router.navigate(['']);
      }, (res) => {
        res.data.errors.forEach((value) => this.toastr.error(value[0], 'Error!'));
      });

  }

}
