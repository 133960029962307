<div class="container-sm">

  <h2>Reingresar Contraseña</h2>

  <p>Buenísimo! Ahora podés ingresar una contraseña nueva. Necesitamos que tenga más de 8 caracteres.</p>

  <form (ngSubmit)="reset(f)" #f="ngForm" novalidate>

    <div class="form-group">
      <label for="input-password">Contraseña</label>
      <input type="password" class="form-control has-feedback" id="input-password" name="password"
             [(ngModel)]="password"
             #passwordInput="ngModel"
             [ngClass]="{ 'is-invalid': passwordInput.invalid && (passwordInput.dirty || passwordInput.touched || f.submitted )}"
             required
             minlength="8"
      >
      <div *ngIf="passwordInput.invalid && (passwordInput.dirty || passwordInput.touched || f.submitted )" class="invalid-feedback">
        <div *ngIf="passwordInput.errors['required']">Olvidaste ingresar tu contraseña.</div>
        <div *ngIf="passwordInput.errors['minlength']">Necesitamos una contraseña de al menos {{ passwordInput.errors.minlength.requiredLength }} caracteres.</div>
      </div>
      <small class="form-text text-muted" *ngIf="!passwordInput.value">Por favor, al menos 8 caracteres.</small>
    </div>
    <div class="form-group is-invalid">
      <label for="input-password-again">Repetir contraseña</label>
      <input type="password" class="form-control" id="input-password-again" name="password_confirmation"
             [(ngModel)]="password_confirmation"
             #passwordConfirmationInput="ngModel"
             [ngClass]="{ 'is-invalid': passwordConfirmationInput.invalid && (passwordConfirmationInput.dirty || passwordConfirmationInput.touched || f.submitted) }"
             required
             appEquals="password"
      >
      <div *ngIf="passwordConfirmationInput.invalid && (passwordConfirmationInput.dirty || passwordConfirmationInput.touched || f.submitted)" class="invalid-feedback">
        <div *ngIf="passwordConfirmationInput.errors['notequal']">Las contraseñas deben ser iguales!.</div>
      </div>
    </div>


    <button type="submit" class="btn btn-lg btn-block btn-primary">Enviar</button>

  </form>

</div>
