import { Component, OnInit } from '@angular/core';
import {Route} from '../../models/route.model';
import {Trip} from '../../models/trip.model';
import {PurchaseService} from '../../services/purchase.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  route: Route;
  trips: Trip[];
  timesSelected = false;

  constructor(private purchaseService: PurchaseService) {

  }

  ngOnInit(): void {

    this.purchaseService.all()
      .subscribe((purchase) => {
        this.route = purchase.route || null;
        this.trips = purchase.times || purchase.trips || null;
        this.timesSelected = !!purchase.times;
      });

  }

}
