import { Component, OnInit } from '@angular/core';
import {PurchaseService} from '../../../services/purchase.service';
import {Trip} from '../../../models/trip.model';
import {Router} from '@angular/router';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-times',
  templateUrl: './times.component.html',
  styleUrls: ['./times.component.scss']
})
export class TimesComponent implements OnInit {

  faCheck = faCheck;

  trips: Trip[];

  groups: {
    date: Date,
    trips: Trip[],
    selected: Trip,
  }[];

  constructor(private purchaseService: PurchaseService,
              private router: Router) { }

  ngOnInit(): void {

    this.purchaseService.checkStepAndNavigateIfInvalid('dates');

    this.trips = this.purchaseService.get('dates').trips;
    this.groups = [];

    console.log(this.trips);

    this.trips.forEach((trip) => {
      const date = new Date(trip.date);
      const existingGroup = this.groups.find(group => group.date.toDateString() === date.toDateString()) || null;
      if (!existingGroup) {
        this.groups.push({ date, trips: [trip], selected: trip });
      } else {
        existingGroup.trips.push(trip);
      }
    });

  }

  ok(): void {

    this.purchaseService.set('times',
      this.groups
        .map(group => group.selected)
    );

    console.log('trips to buy', this.purchaseService.get('times'));

    this.router.navigate(['steps/checkout']);

  }

}
