<h1>
  Elegí el horario de cabecera
  <span *ngIf="groups.length > 1">
    para cada día
  </span>
</h1>

<div *ngFor="let group of groups">
  <h3>{{ group.date | date:'dd/MM/yyyy':'+0' }}</h3>
  <div class="list-group list-group-horizontal mb-4">
    <a class="list-group-item list-group-item-action"
       *ngFor="let trip of group.trips"
       (click)="group.selected = trip;"
       [class.active]="group.selected.id === trip.id"
    >
      <fa-icon [icon]="faCheck" *ngIf="group.selected.id === trip.id"></fa-icon>
      {{ trip.date | date: 'HH:mm' }}
      <span class="badge badge-secondary" *ngIf="trip.name">{{ trip.name }}</span>
    </a>
  </div>
</div>

<button class="btn btn-success btn-block btn-lg"
        (click)="ok()">
  Continuar
</button>

