<nav class="navbar navbar-expand-sm navbar-light bg-light">
  <div class="container-sm">
    <a class="navbar-brand" href="#">
      Paxtracker
    </a>

    <button class="navbar-toggler" type="button"
            aria-expanded="false"
            aria-label="Toggle navigation"
            (click) = "showNav = !showNav"
            *ngIf="isAuthenticated"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse"
         *ngIf="isAuthenticated"
         (click)="showNav = false"
         [class.show]="showNav"
    >
      <ul class="navbar-nav ml-auto">
        <li class="nav-item navbar-text">
          {{ user.first_name }} {{ user.last_name }}
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="steps/route">Nueva Reserva</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" routerLink="tickets">Mis Reservas</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" (click)="logout()">Salir</a>
        </li>
      </ul>
    </div>
  </div>


</nav>
