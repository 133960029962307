import {Component, Input, OnInit} from '@angular/core';
import {ModalNotificationOptions} from './modal-notification.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {faCheckCircle, faExclamationTriangle, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal-notification',
  templateUrl: './modal-notification.component.html',
  styleUrls: ['./modal-notification.component.scss']
})
export class ModalNotificationComponent {

  faTimesCircle = faTimesCircle;
  faExclamationTriangle = faExclamationTriangle;
  faCheckCircle = faCheckCircle;

  @Input() options: ModalNotificationOptions;

  constructor(private activeModal: NgbActiveModal) { }

  public yes(): void {
    this.activeModal.close(true);
  }

  public no(): void {
    this.activeModal.close(false);
  }

}
