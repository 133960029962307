import {ActivatedRouteSnapshot, Resolve, RouterState, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Route} from '../../../models/route.model';
import {RouteService} from '../../../services/route.service';
import {PurchaseService} from '../../../services/purchase.service';

@Injectable({ providedIn: 'root' })
// @ts-ignore
export class RouteOneResolver implements Resolve<Route[]> {
  constructor(private routeService: RouteService,
              private purchaseService: PurchaseService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    month?: string,
  ): Observable<any[]> {
    return this.routeService.trips(
      this.purchaseService.get('route').id
    );
  }
}
