import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './components/auth/login/login.component';
import {RouteComponent} from './components/steps/route/route.component';
import {AuthGuard} from './auth.guard';
import {RouteAllResolver} from './components/steps/route/route.all.resolver';
import {DatesComponent} from './components/steps/dates/dates.component';
import {TimesComponent} from './components/steps/times/times.component';
import {RouteOneResolver} from './components/steps/dates/route.one.resolver';
import {CheckoutComponent} from './components/steps/checkout/checkout.component';
import {StepsComponent} from './components/steps/steps.component';
import {TicketsComponent} from './components/tickets/tickets.component';
import {TicketService} from './services/ticket.service';
import {RegisterComponent} from './components/auth/register/register.component';
import {RegisterCodeResolver} from './components/auth/registerCode.resolver';
import {ForgotPasswordComponent} from './components/auth/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './components/auth/reset-password/reset-password.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/steps/route',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'tickets',
    component: TicketsComponent,
    resolve: {
      tickets: TicketService,
    }
  },
  {
    path: 'steps',
    canActivate: [AuthGuard],
    component: StepsComponent,
    children: [
      {
        path: 'route',
        component: RouteComponent,
        resolve: {
          routes: RouteAllResolver,
        },
      },
      {
        path: 'dates',
        component: DatesComponent,
        resolve: {
          trips: RouteOneResolver,
        },
      },
      {
        path: 'times',
        component: TimesComponent,
        resolve: {
        },
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        resolve: {
        },
      }
    ]
  },
  {
    path: 'auth',
    children: [
      {
        path: 'register',
        component: RegisterComponent,
        resolve: {
          user: RegisterCodeResolver,
        },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'reset-password/:email/:token',
        component: ResetPasswordComponent,
      },
    ]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
