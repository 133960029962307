import {ActivatedRouteSnapshot, Resolve, RouterState, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Route} from '../../../models/route.model';
import {RouteService} from '../../../services/route.service';

@Injectable({ providedIn: 'root' })
// @ts-ignore
export class RouteAllResolver implements Resolve<Route[]> {
  constructor(private routeService: RouteService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.routeService.all();
  }
}
