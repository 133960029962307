<div class="container-sm mt-2">

  <h2>Ingresá</h2>

  <div class="alert alert-danger" *ngIf="!!errorMessage">
    {{ errorMessage }}
  </div>

  <form (ngSubmit)="onSubmit(f.form)" #f="ngForm" novalidate>

    <div class="form-group">
      <label for="input-email">Email</label>
      <input type="email" class="form-control" id="input-email" name="email"
             [(ngModel)]="user.email"
             #email="ngModel"
             [ngClass]="{ 'is-invalid': f.submitted && email.invalid }"
             required email
      >
    </div>

    <div class="form-group">
      <label for="input-password">Contraseña</label>
      <input type="password" class="form-control" id="input-password" name="password"
             [(ngModel)]="user.password"
             #password="ngModel"
             [ngClass]="{ 'is-invalid': f.submitted && password.invalid }"
             required
             minlength="6"
      >
      <small class="help-text float-right"><a routerLink="/auth/forgot-password">Olvidé mi contraseña.</a></small>
    </div>
    <br>

    <button type="submit" class="btn btn-lg btn-block btn-primary">Ingresar</button>

  </form>

</div>


