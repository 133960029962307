import {EventEmitter, Injectable, Output, OnInit} from '@angular/core';
import {User} from '../models/user.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() status: EventEmitter<boolean> = new EventEmitter<boolean>();

  private currentUserSubject = new BehaviorSubject<User>(null);

  currentUser = this.currentUserSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) {
    const localStorageUser = localStorage.getItem('user');
    if (localStorageUser) {
      this.currentUserSubject.next(new User(JSON.parse(localStorageUser)));
    }
  }

  login(user: User): Observable<any> {
    return this.http
      .post('auth/login', user)
      .pipe(map((response: { data: { user: object, token: string }}) => this.processLogin(response.data)));
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('token');
  }

  logout(): void {
    this.currentUserSubject.next(null);
    this.status.emit(false);
    localStorage.clear();
  }

  processLogin(data: { token: string, user: object} ): User {
    const user = new User(data.user);
    localStorage.setItem('token', data.token);
    localStorage.setItem('user', JSON.stringify(user));
    this.status.emit(true);
    this.currentUserSubject.next(user);
    return user;
  }

  checkRegisterCode(code): Observable<User> {
    return this.http.post('booking/checkRegisterCode', { code })
      .pipe(map((data: { user: object }) => new User(data.user)));
  }

  verifyUser(code, password): Observable<User> {
    return this.http
      .post('user/verify', { code, password, password_confirmation: password })
      .pipe(map((response: { data: { user: object, token: string } }) => this.processLogin(response.data)));
  }

  resetPassword(data: any): Observable<User> {
    return this.http
      .post('auth/password/reset', data)
      .pipe(map((response: { data: { user: object, token: string } }) => this.processLogin(response.data)));
  }

}
