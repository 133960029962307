<div class="container-sm">

  <h2>Mis Reservas</h2>

  <ul id="tickets">
    <li *ngFor="let ticket of tickets">
      <button class="btn btn-outline-danger float-right"
              (click)="cancel(ticket)"
      >Cancelar</button>
      <dl>
        <dt>Recorrido</dt>
        <dd>{{ ticket.routeName }}</dd>
      </dl>
      <dl>
        <dt>Fecha y Hora</dt>
        <dd>{{ ticket.time | date:'dd/MM/yyyy HH:mm' }}</dd>
      </dl>
    </li>
  </ul>

  <div class="alert alert-info" *ngIf="!tickets.length">
    No reservaste pasajes para los próximos días. <a routerLink="/steps/route">Reservar ahora</a>.
  </div>

</div>
