import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Route} from '../../../models/route.model';
import {PurchaseService} from '../../../services/purchase.service';
import {AuthService} from '../../../services/auth.service';
import {ModalNotificationService} from '../../../_shared/modal-notification/modal-notification.service';

@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss']
})
export class RouteComponent implements OnInit {

  direction = 'Entrada';

  routes: Route[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private purchaseService: PurchaseService,
    private authService: AuthService,
    private modal: ModalNotificationService,
  ) {
    this.route.data.subscribe((data) => this.routes = data.routes);

    this.authService.currentUser
      .subscribe((user) => {
        if (user.booking_limit === 0) {
          this.modal.warning({
            title: 'Límite excedido',
            body: `Lo sentimos, no es posible tener más de ${user.company.booking_limit} activos.`,
          });
        }
      });

  }

  ngOnInit(): void {
    this.purchaseService.set('route', null);
  }

  chooseRoute(route: Route): void {

    this.purchaseService.set('route', route);

    this.router.navigate(['steps/dates']);

  }

}
