import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {PurchaseService} from '../../../services/purchase.service';
import {RouteService} from '../../../services/route.service';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {Trip} from '../../../models/trip.model';

@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatesComponent implements OnInit {

  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;

  @ViewChild('dp') dp;

  startDate: NgbDate;
  minDate: NgbDate;
  trips: any[];
  showDatepicker = false;

  selectedDates: NgbDate[] = [];
  multipleDates = false;

  currentMonth: { year: number, month: number };
  validDate: boolean;

  constructor(private router: Router,
              private purchase: PurchaseService,
              private routeService: RouteService,
              private calendar: NgbCalendar,
              private i18n: NgbDatepickerI18n,
              private route: ActivatedRoute) { }

  ngOnInit(): void {

    const today = this.calendar.getToday();
    this.minDate = today;
    this.currentMonth = { month: today.month, year: today.year };

    this.purchase.checkStepAndNavigateIfInvalid('dates');
    this.purchase.all()
      .subscribe((data) => this.validDate = !!data.dates);
    this.route.data
      .subscribe((data) => {
        this.trips = data.trips;
        this.showDatepicker = true;
        this.setDataFromPurchase();
      });
  }

  setDataFromPurchase(): void {
    const dataDates = this.purchase.get('dates');
    if (!dataDates) {
      return;
    }
    this.selectedDates = dataDates.selected;
    this.multipleDates = dataDates.selected.length > 1;
    this.computeDates();
  }

  onDateSelection(date?: NgbDate): void {
    if (date) {
      if (this.multipleDates) {
          if (this.isSelected(date)) {
            this.selectedDates = this.selectedDates.filter((d) => !date.equals(d));
          } else {
            this.selectedDates.push(date);
          }
      } else {
        this.selectedDates = [date];
      }
    } else {
      this.selectedDates = [];
    }
    this.computeDates();
  }

  isSelected(date: NgbDate): boolean {
    return !!this.selectedDates.find((d) => date.equals(d));
  }

  isDisabled = (date: NgbDateStruct): boolean => {

    let disabled = true;

    this.trips.forEach((trip) => {
      if (date.year === parseInt(trip.date.substr(0, 4), 10)
        &&  date.month === parseInt(trip.date.substr(5, 2), 10)
        &&  date.day === parseInt(trip.date.substr(8, 2), 10)
        &&  trip.available >= 1) {
        disabled = false;
      }
    });

    return disabled;
  }

  isPast(date: NgbDate): boolean {
    return date.before(this.calendar.getToday());
  }

  computeDates(): void {

    const selectedTrips = [];

    this.selectedDates.forEach((date) => {
      if (!this.isDisabled(date)) {
        selectedTrips.push.apply(selectedTrips, this.getTripsByDate(date));
      }
    });

    this.purchase.set('dates', {
      selected: this.selectedDates,
      trips: selectedTrips
    });

  }

  getTripsByDate(date: NgbDate): Trip[] {
    return this.trips.filter((trip) =>
          date.year === parseInt(trip.date.substr(0, 4), 10)
      &&  date.month === parseInt(trip.date.substr(5, 2), 10)
      &&  date.day === parseInt(trip.date.substr(8, 2), 10)
    );
  }

  ngbDateFromString(date: string): NgbDate {
    return new NgbDate(
      parseInt(date.substr(0, 4), 10),
      parseInt(date.substr(5, 2), 10),
      parseInt(date.substr(8, 2), 10)
    );
  }

  changePeriodic(): void {
    this.onDateSelection();
  }

  getMonthFullName(date): string {
    if (!date) {
      return null;
    }
    return this.i18n.getMonthFullName(date.month, date.year);
  }

  nextMonth(): void {
    if (this.currentMonth.month === 12) {
      this.currentMonth.year++;
      this.currentMonth.month = 0;
    }
    this.dp.navigateTo({ year: this.currentMonth.year, month: this.currentMonth.month + 1 });
  }

  previousMonth(): void {
    if (this.currentMonth.month === 1) {
      this.currentMonth.year--;
      this.currentMonth.month = 13;
    }
    this.dp.navigateTo({ year: this.currentMonth.year, month: this.currentMonth.month - 1 });
  }

  isCurrentMonth(month): boolean {

    if (!month) {
      return false;
    }
    return (month.year === this.calendar.getToday().year && month.month === this.calendar.getToday().month);

  }
}
