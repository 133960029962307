import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Ticket} from '../models/ticket.model';
import {RouteService} from './route.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketService implements Resolve<Ticket[]>{

  constructor(private http: HttpClient) {}

  all(): Observable<Ticket[]> {
    return this.http.get('tickets')
      .pipe(map((tickets: { data: any[] }) => tickets.data.map((ticket) => new Ticket(ticket))));
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.all();
  }

  cancel(ticket: Ticket): Observable<Ticket> {
    return this.http.delete(`tickets/${ticket.id}`)
      .pipe(map((response: any) => new Ticket(response.ticket)));
  }

}
