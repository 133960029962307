import { Injectable } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalNotificationComponent} from './modal-notification.component';

export interface ModalNotificationOptions {
  title: string;
  body: string;
  type?: 'danger' | 'warning' | 'success';
  cancelText?: string;
  cancelStatus?: string;
  acceptText?: string;
  acceptStatus?: string;
  cancelShow?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ModalNotificationService {

  constructor(private modalService: NgbModal) { }

  confirm(options: ModalNotificationOptions): Promise<boolean> {
    return this._open({
      cancelText: 'No',
      acceptText: 'Sí',
      cancelStatus: 'danger',
      acceptStatus: 'success',
      cancelShow: true,
      ...options,
    });
  }

  error(options: ModalNotificationOptions): Promise<boolean> {
    return this._open({
      type: 'danger',
      acceptText: 'Aceptar',
      cancelShow: false,
      acceptStatus: 'primary',
      ...options,
    });
  }

  warning(options: ModalNotificationOptions): Promise<boolean> {
    return this._open({
      type: 'warning',
      acceptText: 'Aceptar',
      cancelShow: false,
      acceptStatus: 'primary',
      ...options,
    });
  }

  _open(options: ModalNotificationOptions) {
    const ref = this.modalService.open(ModalNotificationComponent, { backdrop: 'static' });
    ref.componentInstance.options = options;
    return ref.result;
  }

}
