import {Company} from './company.model';

export class User {
  id: number;
  // tslint:disable-next-line:variable-name
  company_id: number;
  email: string;
  password: string;
  // tslint:disable-next-line:variable-name
  first_name: string;
  // tslint:disable-next-line:variable-name
  last_name: string;
  name: string;
  // tslint:disable-next-line:variable-name
  booking_limit?: number;

  company: Company;

  constructor(data?) {
    Object.assign(this, data);
  }

}
