<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <fa-icon [icon]="faTimesCircle" *ngIf="options.type === 'danger'" class="text-danger"></fa-icon>
    <fa-icon [icon]="faExclamationTriangle" *ngIf="options.type === 'warning'" class="text-warning"></fa-icon>
    <fa-icon [icon]="faCheckCircle" *ngIf="options.type === 'success'" class="text-success"></fa-icon>
    {{ options.title }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="no()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <p>{{ options.body }}</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-{{ options.cancelStatus }}"
          *ngIf="options.cancelShow"
          (click)="no()"
  >{{ options.cancelText }}</button>
  <button type="button" class="btn btn-{{ options.acceptStatus }}"
          (click)="yes()"
  >{{ options.acceptText }}</button>
</div>
