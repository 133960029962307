export class Trip {
  id: number;
  name: string;
  date: string;

  constructor(data) {
    Object.assign(this, data);
  }

}
