import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Ticket} from '../../models/ticket.model';
import {TicketService} from '../../services/ticket.service';
import {ModalNotificationService} from '../../_shared/modal-notification/modal-notification.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

  tickets: Ticket[];

  constructor(private route: ActivatedRoute,
              private ticketService: TicketService,
              private modalService: ModalNotificationService,
              private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.route.data
      .subscribe((data) => this.tickets = data.tickets);
  }

  cancel(ticket): void {

    this.modalService.confirm({
      type: 'warning',
      title: 'Atención',
      body: '¿Estás seguro de cancelar esta reserva?',
    }).then((result) => {
      if (result) {
        this.ticketService
          .cancel(ticket)
          .subscribe((cancelledTicket) => {
            this.tickets = this.tickets.filter((ti) => ti.id !== cancelledTicket.id);
            this.toastrService.success('Reserva cancelada con éxito', '');
          });

      }
    });

  }

}
