<div class="container-sm">

  <h1>Reiniciar Contraseña</h1>

  <p>Te enviaremos por correo electrónico un vínculo para que puedas ingresar una contraseña nueva.</p>

  <form (ngSubmit)="onSubmit(f.form)" #f="ngForm" novalidate>

    <div class="form-group"  [class.has-error]="(emailInput.invalid) && ( f.submitted || emailInput.touched)">
      <label for="input-email">Email</label>
      <input type="email" class="form-control" id="input-email" name="email"
             [(ngModel)]="email"
             #emailInput="ngModel"
             [class.is-invalid]="f.submitted && emailInput.invalid"
             required email
      >
      <p *ngIf="f.hasError('required', 'email') && ( f.submitted || emailInput.touched)" class="invalid-feedback">Olvidaste escribir tu email.</p>
      <p *ngIf="f.hasError('email', 'email') && ( f.submitted || emailInput.touched)" class="invalid-feedback">Este no parece ser un email válido.</p>
    </div>


    <button type="submit" class="btn btn-lg btn-block btn-primary">Enviar</button>

  </form>

</div>
