import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: string;
  serverError: string;

  constructor(private router: Router,
              private toastrService: ToastrService,
              private http: HttpClient) { }

  ngOnInit(): void {
  }

  onSubmit(f): void {

    if (f.invalid) {
      return;
    }
    this.http
      .post('auth/password/email', { email: this.email })
      .subscribe(() => {
        this.toastrService.success('Por favor, buscá en tu email las intrucciones para reiniciar tu contraseña.', '¡Buenísimo!')
        this.router.navigate(['/login']);
      }, (res) => {
        for (const error in Object.keys(res.data.errors)) {
          if (res.data.errors.hasOwnProperty(error)) {
            this.serverError += res.data.errors[error] + ' ';
          }
        }
      });
  }

}
