<div class="container-sm">

  <h2>Activemos tu cuenta</h2>

  <p>Bienvenido <strong>{{ this.user?.name }}</strong>!<br>
    Configuremos tu contraseña para que puedas reservar pasajes por cuenta de <strong>{{ this.user?.company.name }}</strong>.
  </p>

  <form [formGroup]="form" #f="ngForm" (submit)="ok()">

    <div class="form-group">
      <label for="input-password">Contraseña</label>
      <input type="password" class="form-control" id="input-password" name="password" autocomplete="new-password"
             formControlName="password"
             [class.is-invalid]="f.submitted && form.controls.password.invalid"
      >
      <div class="invalid-feedback" *ngIf="form.controls.password.hasError('required')">
        Por favor, ingresá una contraseña.
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.password.hasError('minlength')">
        Tu contraseña debe tener al menos {{ form.controls.password.errors.minlength.requiredLength }} caracteres.
      </div>
    </div>

    <div class="form-group">
      <label for="input-password-confirmation">Contraseña, otra vez</label>
      <input type="password" class="form-control" id="input-password-confirmation" name="password" autocomplete="new-password"
             formControlName="passwordConfirmation"
             [class.is-invalid]="f.submitted && (form.errors || form.controls.passwordConfirmation.invalid)"
      >

      <div class="invalid-feedback" *ngIf="form.errors">
        Las contraseñas son distintas.
      </div>
      <div class="invalid-feedback" *ngIf="form.controls.passwordConfirmation.hasError('required')">
        Por favor, confirmá tu contraseña.
      </div>

    </div>

    <button class="btn btn-success btn-block btn-lg">
      Activar mi Cuenta
    </button>

  </form>

</div>


