import {Route} from './route.model';

export class Ticket {

  id: number;
  routeName: string;
  time: string;

  route: Route;

  constructor(data) {
    Object.assign(this, data);
    if (!(data.route instanceof Route)) {
      this.route = new Route(data.route);
    }
  }

}
