
<app-header></app-header>

<router-outlet></router-outlet>

<ngx-spinner
  bdColor="rgba(255, 255, 255, 0.8)"
  size="medium"
  color="rgba(184, 22, 3, 1)"
  type="ball-clip-rotate"
  fullScreen="true"
></ngx-spinner>

<p class="signature">{{ signature }}</p>
