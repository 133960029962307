import {ActivatedRouteSnapshot, Resolve, RouterState, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Injectable({ providedIn: 'root' })
// @ts-ignore
export class RegisterCodeResolver implements Resolve<Route[]> {
  constructor(private authService: AuthService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.authService.checkRegisterCode(route.queryParams.code);
  }
}
