
<h1>Por favor, elegí el recorrido que querés hacer.</h1>

<ul class="nav nav-pills mb-4">
  <li class="nav-item">
    <a class="nav-link" [class.active]="direction === 'Entrada'" (click)="direction = 'Entrada'">Entrada</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [class.active]="direction === 'Salida'" (click)="direction = 'Salida'">Salida</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [class.active]="direction === ''" (click)="direction = ''">Todos</a>
  </li>
</ul>

<div class="list-group">
  <a class="list-group-item list-group-item-action"
      *ngFor="let route of routes | directionFilter:direction"
      (click)="chooseRoute(route)">
    {{ route.name }}
  </a>
</div>
