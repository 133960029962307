import {Component, OnInit, ViewChild} from '@angular/core';
import {Trip} from '../../../models/trip.model';
import {PurchaseService} from '../../../services/purchase.service';
import {Route} from '../../../models/route.model';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  faSpinner = faSpinner;

  route: Route;
  trips: Trip[];
  loading = false;

  constructor(private purchaseService: PurchaseService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit(): void {

    this.route = this.purchaseService.get('route');
    this.trips = this.purchaseService.get('times');

  }

  ok(): void {

    this.loading = true;
    this.purchaseService
      .save()
      .subscribe((data) => {
        this.toastr.success('Pasaje reservado con éxito!');
        this.router.navigate(['tickets']);
      }, (err) => {
      }, () => {
        this.loading = false;
      });

  }

}
