import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {Route, Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  private data: {
    route: Route,
    dates: any[],
    times: any[]
  };

  private dataBs = new BehaviorSubject<any>(this.data);

  data$ = this.dataBs.asObservable();

  constructor(private router: Router,
              private http: HttpClient) {

    const data = JSON.parse(localStorage.getItem('purchase'));

    if (data === null) {
      this._init();
    } else {
      this.data = data;
      this.dataBs.next(Object.assign({}, this.data));
    }

  }

  public getData(): Observable<number[]> {
    return this.data$;
  }

  _init(save = true): any {

    this.data = {
      route: null,
      dates: null,
      times: null,
    };

    return save ? this._save() : this;

  }

  _save(): any {
    this.dataBs.next(this.data);
    localStorage
      .setItem('purchase', JSON.stringify(this.data));
    return this;
  }

  all(): Observable<any> {
    return this.dataBs.asObservable();
  }

  get(field = null): any {
    const data = JSON.parse(localStorage.getItem('purchase'));
    if (!field) {
      return data;
    }
    return data[field];
  }

  set(field, value): any {
    if (field === 'route') {
      this._init(false);
    }
    if (field === 'time') {
      this.data.dates = null;
    }
    if (field === 'dates') {
     this.setDates(value);
    } else {
      this.data[field] = value;
    }
    this._save();
    return this;
  }

  nextStep(): string {
    let next;
    Object.keys(this.data)
      .forEach(key => {
        if (!next && !this.isValidStep(key)) {
          next = key;
        }
      });
    return next || 'checkout';
  }

  isValidStep(step): boolean {
    return this.data[step] !== null;
  }

  checkStepAndNavigateIfInvalid(step): void {
    if (!this.isValidStep(step)) {
      this.navigateToNextStep();
    }
  }

  navigateToNextStep(): void {
    const nextStep = this.nextStep();
    const url = nextStep === 'checkout' ? 'checkout' : 'steps/' + nextStep;
    console.log('navigate to previous invalid step', url);
    this.router.navigate([url]);
  }

  setDates(value): void {

    const weekdaysSelected = value.weekdays;
    const weekdays = ['', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábados', 'Domingos', ];
    this.data.dates = value;
    // this.data.dates.weekdaysSelectedNames = [];
    //
    // weekdays.forEach(weekdaysSelected, (v, k) => {
    //   if (v) {
    //     // this.data.dates.weekdaysSelectedNames.push(weekdays[k]);
    //   }
    // });

  }

  save(): Observable<any> {
    return this.http.post('booking', this.data);
  }

}
