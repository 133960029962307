import { Pipe, PipeTransform } from '@angular/core';
import {Route} from './models/route.model';

@Pipe({
  name: 'directionFilter'
})
export class DirectionFilterPipe implements PipeTransform {

  transform(routes: Route[], filter: string): Route[] {
    return routes.filter(route => route.name.indexOf(filter) !== -1);
  }

}
