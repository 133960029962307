import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders, HttpResponse
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '../environments/environment';
import {AuthService} from './services/auth.service';
import {catchError, filter, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ModalNotificationService} from './_shared/modal-notification/modal-notification.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private router: Router,
              private spinnerService: NgxSpinnerService,
              private modalService: ModalNotificationService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers: any = {
      'X-Companies-Client-Version': environment.version,
    };
    if (this.authService.isAuthenticated()) {
      const token = localStorage.getItem('token');
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const apiReq = request.clone({
      url: `${environment.apiUrl}/${request.url}`,
      setHeaders: headers
    });
    this.spinnerService.show();
    return next.handle(apiReq)
      .pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
            this.spinnerService.hide();
          }
        }),
        catchError((err) => {
          this.spinnerService.hide();

          let message: string;
          if (err.error.errors && err.error.errors.message) {
            message = err.error.errors.message.join('. ');
          } else if ( err.error.message ) {
            message = err.error.message;
          } else {
            message = 'Ocurrió un error desconocido.';
          }
          this.modalService.error({ title: 'Lo sentimos', body: message });

          if (err.status === 401) {
            this.authService.logout();
            this.router.navigate(['login']);
          }

          return throwError(message);

        })
      );
  }
}
