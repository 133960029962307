import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Route} from '../models/route.model';
import {Trip} from '../models/trip.model';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(private http: HttpClient) { }

  all(): Observable<Route[]> {
    return this.http.get('public/routes')
      .pipe(map((response: any) => response.data.map((route) => new Route(route))));
  }

  one(routeId): Observable<Route> {
    return this.http.get(`public/routes/${routeId}`)
      .pipe(map(response => new Route(response)));
  }

  trips(routeId, month?: string): Observable<any[]> {
    const params = new HttpParams({
      fromObject: { month: month || '' + (new Date()).getMonth() },
    });
    return this.http
      .get(`public/routes/${routeId}/trips`, { params })
      .pipe(map((response: any) => response.data.map(trip => new Trip(trip))));
  }

}
