import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { User} from '../../../models/user.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  user: User;
  form: FormGroup;
  code: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService) {

    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      passwordConfirmation: new FormControl('', [Validators.required]),
    }, {
      validators: [this.checkPasswords],
    });

  }

  ngOnInit(): void {
    this.route.data.subscribe((data) => this.user = data.user);
    this.code = this.route.snapshot.queryParams.code;
  }

  checkPasswords(group: FormGroup): any { // here we have the 'passwords' group
    return group.get('password').value === group.get('passwordConfirmation').value
      ? null
      : { mismatch: true };
  }

  ok(): void {

    if (this.form.invalid) {
      return;
    }

    this.authService
      .verifyUser(this.code, this.form.get('password').value)
      .subscribe((user) => {
        this.router.navigate(['steps', 'route']);
      });

  }

}
