
<app-stepper></app-stepper>

<div class="container-sm">
  <div class="row">
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
